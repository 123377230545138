import { Children, ReactNode } from 'react';

function Join(props: { children?: ReactNode; by: ReactNode }) {
  const { children, by } = props;

  return (
    <>
      {Children.map(
        Children.toArray(children).filter(Boolean),
        (child, index) => (
          <>
            {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
            {child !== null && index !== 0 && <>{by}</>}
            {child}
          </>
        ),
      )}
    </>
  );
}

export default Join;
