import { ReactNode } from 'react';

function Paper(props: { children?: ReactNode }) {
  const { children } = props;

  return (
    <div className="flex w-full flex-col md:rounded-16 md:bg-white md:p-64 md:shadow-2">
      {children}
    </div>
  );
}

export default Paper;
