'use client';

import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

function Background(props: {
  children?: ReactNode;
  backgroundColor: `#F8F8F8` | `#FFFFFF`;
}) {
  const { children, backgroundColor } = props;

  return (
    <>
      <div className="relative">{children}</div>
      <div
        id="background"
        className={twMerge(
          `fixed inset-0 z-[-10]`,
          backgroundColor === `#F8F8F8` ? `max-md:bg-[#F8F8F8]` : ``,
          backgroundColor === `#FFFFFF` ? `max-md:bg-[#FFFFFF]` : ``,
          `md:bg-[radial-gradient(194.94%_68.13%_at_100%_20.08%,_rgba(255,_207,_186,_0.53)_0%,_rgba(192,_187,_253,_0.27)_100%)]`,
          `md:opacity-[0.14]`,
          `md:blur-[25px]`,
        )}
      />
    </>
  );
}

export default Background;
