import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

function Container(props: {
  children?: ReactNode;
  maxWidth?: number;
  className?: string;
}) {
  const { children, maxWidth, className } = props;

  return (
    <div className="w-full md:container">
      <div
        className={twMerge(`mx-auto`, className)}
        style={{
          maxWidth,
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default Container;
