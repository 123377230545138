import {
  UilFilter,
  UilSetting,
  UilSignout,
  UilUserCircle,
  UilUserPlus,
} from '@iconscout/react-unicons';
import React from 'react';

import SetupIncomplete from '@/components/Molecule/Home/SetupIncomplete';
import { useAuth } from '@/hooks/useAuth';
import { InternalLink } from '@/hooks/useInternalRouter';

function ProfileListBox(props: {
  onClose?: {
    (_: unknown): unknown;
  };
}) {
  const { onClose } = props;

  const { logout, me } = useAuth();

  if (!me) {
    return null;
  }

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="fixed inset-0 z-10 h-screen w-screen cursor-pointer"
        onClick={onClose}
      />

      <div className="absolute right-0 z-10 mt-23 flex w-308 flex-col overflow-hidden rounded-20 border border-solid border-gray-400 bg-white shadow-popup">
        <div className="flex flex-col gap-24 p-24">
          <div className="flex items-center gap-12">
            <div className="flex-center size-32 shrink-0 rounded-full bg-gray-25">
              <span className="header-5-m capitalize text-gray-500">
                {me.username[0]}
              </span>
            </div>
            <span className="label-small capitalize text-gray-25">
              {me.username}
            </span>
          </div>
          {!me.isStripeSetupComplete && (
            <InternalLink href="/settings">
              <SetupIncomplete />
            </InternalLink>
          )}
        </div>

        <div className="h-1 w-full bg-gray-400" />

        <div className="flex flex-col gap-24 p-24">
          <InternalLink href="/profile">
            <div className="flex items-center gap-12">
              <div className="flex-center size-32">
                <UilUserCircle className="size-20 fill-gray-25" />
              </div>
              <span className="body-2-r text-gray-25">My Profile</span>
            </div>
          </InternalLink>
          <InternalLink href="/preference">
            <div className="flex items-center gap-12">
              <div className="flex-center size-32">
                <UilFilter className="size-20 fill-gray-25" />
              </div>
              <span className="body-2-r text-gray-25">Show Preferences</span>
            </div>
          </InternalLink>
          <InternalLink href="/settings">
            <div className="flex items-center gap-12">
              <div className="flex-center size-32">
                <UilSetting className="size-20 fill-gray-25" />
              </div>
              <span className="body-2-r text-gray-25">Account Settings</span>
            </div>
          </InternalLink>
          {me.artists.length > 0 && me?.ableToCreateNewArtistProfile && (
            <InternalLink href="/profile/add" className="contents">
              <div className="flex w-full items-center gap-12">
                <div className="flex-center size-32">
                  <UilUserPlus className="size-20 fill-gray-25" />
                </div>
                <span className="body-2-r truncate text-gray-25">
                  Add New Profile
                </span>
              </div>
            </InternalLink>
          )}
        </div>
        <div className="h-1 w-full bg-gray-400" />

        <div className="flex flex-col gap-24 p-24">
          <button onClick={logout}>
            <div className="flex items-center gap-12">
              <div className="flex-center size-32">
                <UilSignout className="size-20 fill-gray-25" />
              </div>
              <span className="body-2-r text-gray-25">Log Out</span>
            </div>
          </button>
        </div>
      </div>
    </>
  );
}

export default ProfileListBox;
