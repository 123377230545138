import { UisAngleDown } from '@iconscout/react-unicons-solid';
import React, { useCallback, useState } from 'react';

// eslint-disable-next-line import/no-cycle
import AppBar from '@/components/Layout/AppBar';
import ProfileListBox from '@/components/Molecule/Profile/ProfileListBox';
import { useAuth } from '@/hooks/useAuth';

function ProfileBlock() {
  const { me } = useAuth();

  const [showProfileSheet, setShowProfileSheet] = useState(false);

  const toggleShowProfileSheet = useCallback(() => {
    setShowProfileSheet((x) => !x);
  }, []);

  if (!me) {
    return (
      <>
        <AppBar.LearnMoreButton />
        <AppBar.LoginButton />
      </>
    );
  }

  return (
    <div className="relative">
      <button
        className="relative flex items-center gap-10"
        onClick={toggleShowProfileSheet}
      >
        <div className="flex-center size-32 shrink-0 rounded-full bg-gray-25">
          <span className="header-5-m capitalize text-gray-500">
            {me.username[0]}
          </span>
        </div>
        <div className="w-full overflow-hidden">
          <div className="flex size-24 shrink-0 items-center justify-center">
            <UisAngleDown className="size-24 fill-gray-25" />
          </div>
        </div>
      </button>

      {showProfileSheet && <ProfileListBox onClose={toggleShowProfileSheet} />}
    </div>
  );
}

export default ProfileBlock;
